import React, { useEffect, useState } from 'react';
import ChatBot from "react-chatbotify";
import chatbotConfig from './chatbotConfig';
import { Button } from 'react-chatbotify';
import { v4 as uuidv4} from 'uuid';

const MyChatBot = () => {
	const [sessionId, setSessionId] = useState(''); // State to store the user's IP address
	const [currentPath, setCurrentPath] = useState(''); // State to store the current directory or subpath
	let hasError = false;

	const generateSessionId = () => {
		const randomUUID = uuidv4();	// Generates a random UUID
		const timestamp = Date.now()
		return `session_${timestamp}_${randomUUID}`
	}

	// New function that uses browser's localStorage for Session Management
	useEffect(() => {
		// Check if a session ID already exists in localStorage
		const existingSessionId = localStorage.getItem('sessionId');
		if (existingSessionId) {
			console.log(`* Session ID already exists: ${existingSessionId}`)
			setSessionId(existingSessionId)	// Use the existing session ID
		} else {
			// Generate a new session ID and store it in localStorage
			const newSessionId = generateSessionId();
			localStorage.setItem('sessionId', newSessionId);
			console.log(`* Session ID created: ${newSessionId}`)
			setSessionId(newSessionId);	// Set the new session ID
		}

	}, []);

	// useEffect(() => {
	// 	// Function to extract a specific cookie by name
	// 	const getCookie = (name) => {
	// 		// console.log(`document.cookie: ${document.cookie}`)
	// 		const value = `; ${document.cookie}`;
	// 		// console.log(`*** value: ${value}`)
	// 		const parts = value.split(`; ${name}=`);
	// 		// console.log(`*** parts: ${parts}`)
	// 		if (parts.length === 2) return parts.pop().split(';').shift();
	// 		return null;
	// 	};
	
	// 	// Extract the X-Session-ID cookie
	// 	const sessionCookie = getCookie('X-Session-ID');
	// 	if (sessionCookie) {
	// 		console.log('Session ID from cookie:', sessionCookie);
	// 		setSessionId(sessionCookie); // Store the session ID in state
	// 	} else {
	// 		console.error('X-Session-ID cookie not found');
	// 	}
	// }, []);

	// Capture the current directory or subpath
    useEffect(() => {
        const path = window.location.pathname; // Get the current path from the URL
        console.log('Current Path:', path); // Log the path for debugging
        setCurrentPath(path); // Store the path in state
    }, []);

	// Generate chatbot settings based on the effectivePath
	const getChatBotSettings = (effectivePath) => {
		// Normalize effectivePath and remove leading/trailing slashes
		const normalizedPath = effectivePath.replace(/^\//, '').replace(/\/$/, '');
		// Use the settings for the effectivePath or default to 'assistant'
		return chatbotConfig[normalizedPath] || chatbotConfig['assistant'];
	};
	
	// Amalia Endpoint
	const call_amalia = async (params) => {
		console.log(`params: ${JSON.stringify(params, null, 2)}`);
		console.log(`sessionID: ${sessionId}`)
		console.log(`currentPath: ${currentPath}`)

		let effectivePath = currentPath;
		if (!currentPath || currentPath === '/')
		{
			effectivePath = 'coastlinetravel'
		}

		const url_to_use = `https://chats.mytrip.ai/${effectivePath}-assistant/chat`
		console.log(`url to use: ${url_to_use}`)

		// Append the effectivePath to the sessionId
		const clientIdWithEffectivePath = `${sessionId || 'unknown_user_chatbotify'}-${effectivePath}`;
		console.log(`client_id about to send: ${clientIdWithEffectivePath}`);


		try {
			// const response = await fetch('https://chats.mytrip.ai/coastlinetravel-assistant/chat', {
			const response = await fetch(url_to_use, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					message: params.userInput, // dynamically use the user's input
					// client_id: 'testing_261120241143', // replace with a relevant client_id if needed
					client_id: clientIdWithEffectivePath, // replace with a relevant client_id if needed
					// flag: 1,
				}),
			});

			if (!response.ok) {
				throw new Error(`Error: ${response.statusText}`);
			}

			const data = await response.json();

			// Assuming the response has a field called `reply` for the chatbot's response
			if (data.response) {
				await params.injectMessage(data.response);
			} else {
				throw new Error("Response did not include a reply field");
			}
		} catch (error) {
			console.error(error);
			await params.injectMessage("Unable to connect to the chat service. Please try again.");
			hasError = true;
		}
	};


	const effectivePath = currentPath.replace(/^\//, '').replace(/\/$/, '') || 'coastlinetravel';
	const chatbotSettings = getChatBotSettings(effectivePath);

	const flow={
		start: {
			message: chatbotSettings.message, // "Hi, I'm the Coastline Travel AI Assistant. Can I help you find contacts for exquisite lodging and journeys from our top-shelf providers? Where would your clients like to go?",
			path: (params) => {
			hasError = false; 
			return "loop";
			},
			// isSensitive: true,
		},

		loop: {
			message: async (params) => {
				await call_amalia(params);
			},
			path: () => {
				if (hasError) {
					return "start"
				}
				return "loop"
			}
		}
	}
	
	


	return (
		// <ChatBot flow={flow}	/>

		<ChatBot settings = {chatbotSettings.settings} flow={flow}/>
		// {
		// 	{
		// 		botBubble: {
		// 			showAvatar: true,
		// 			avatar: 'https://travelpro365.com/Images/LandingPage/TravelPro365Favicon.png',
		// 			simStream: true,
		// 			streamSpeed: 20
		// 		},
		// 		chatButton: {
		// 			icon: 'https://travelpro365.com/Images/LandingPage/TravelPro365Favicon.png'
		// 		},
		// 		chatWindow: {
		// 			showScrollbar:true,
		// 			autoJumpToBottom:false,
		// 			showMessagePrompt: true,
		// 			messagePromptText: "New Messages ↓",
		// 			defaultOpen:false,
		// 		},
		// 		chatHistory:
		// 		{
		// 			disabled: false,
		// 			maxEntries: 100,
		// 			storageKey: 'rcb-history', // The key to use for storing chat history. Can be ignored if you only have a single instance of the chatbot on your website. Otherwise, if multiple chatbots share the same storage key, their chat history will overlap.
		// 			storageType: 'LOCAL_STORAGE',
		// 			viewChatHistoryButtonText: 'See Previous Messages',
		// 			chatHistoryLineBreakText: '----- Previous Chat History -----',
		// 			autoLoad: false

		// 		},
		// 		chatInput: {
		// 			disabled: false,
		// 			allowNewline: true,
		// 			enabledPlaceholderText: 'Type your message...',
		// 			showCharacterCount: false,
		// 			characterLimit: -1,
		// 			botDelay: 0,
		// 			// sendButtonIcon: 'https://static-00.iconduck.com/assets.00/send-icon-1024x997-8nyscgy3.png',
		// 			blockSpam: true,	// Blocks input while bot is processing message
		// 			buttons: [Button.SEND_MESSAGE_BUTTON]

		// 		},
		// 		device: {
		// 			desktopEnabled: true,
		// 			mobileEnabled: true,
		// 			applyMobileOptimizations: false		// With this as False, it's possible to see it in mobile
		// 		},
		// 		footer: {
		// 			text: <div style={{ display: 'flex', alignItems: 'center', fontSize: '14px', fontFamily: 'Arial, sans-serif', color: '#666' }}>
		// 			<img 
		// 			  src="https://mytrip.ai/wp-content/uploads/2024/02/mytripailogoheadround20x20.png" 
		// 			  alt="MyTrip.ai logo" 
		// 			  style={{ width: '20px', height: '20px', marginRight: '8px', borderRadius: '50%' }}
		// 			/>
		// 			<span>Powered by MyTrip.ai</span>
		// 		  </div>,
		// 		  buttons: []
		// 		},
		// 		general: {
		// 			primaryColor: '#c16663',
		// 			secondaryColor: '#c16663',
		// 			fontFamily: 'BlinkMacSystemFont',
		// 			showHeader: true,
		// 			showFooter: true,
		// 			embedded: false,
		// 			flowStartTrigger: 'ON_LOAD'		// ON_LOAD || ON_CHATBOT_INTERACT || ON_PAGE_INTERACT
		// 		},
		// 		header: {
		// 			title: <div style={{ cursor: 'pointer', margin: '0px', fontSize: '20px', fontWeight: 'bold' }}>Travel Pro 365</div>,
		// 			showAvatar: true,
		// 			avatar: 'https://travelpro365.com/Images/LandingPage/TravelPro365Favicon.png',
		// 			buttons: [Button.CLOSE_CHAT_BUTTON]

		// 		},
		// 		notification: {
		// 			volume: 1
		// 		},
		// 		tooltip:{
		// 			mode: 'CLOSE',
		// 			text: 'Find a provider with our AI Assistant!'
		// 		},
		// 		userBubble: {
		// 			animate: true,
		// 			showAvatar: true,
		// 			avatar: 'https://mytrip.ai/wp-content/uploads/2024/02/logoheadtranslucent.png',
		// 			simStream: false
		// 		},
		// 	}
		// } flow={flow}/>
	);
};

export default MyChatBot;